<template>
  <div>
    <div class="container">
      <h1 class="h1">{{ $t("sleep.title") }}</h1>

      <!-- <WhiteHolder
        class="sleep-radial-slider"
        :class="{
          'bad-state':
            (form.sleep_time >= 0 && form.sleep_time <= 6) ||
            form.sleep_time >= 11,
          'good-state': form.sleep_time >= 7 && form.sleep_time <= 11,
          error: v$.form.sleep_time.$error,
          'text-bottom': form.sleep_time > 6,
        }"
      >
        <h3 class="h3">{{ $t("sleep.sleep_time") }}</h3>

        <div class="row">
          <div class="col">
            <div class="text-holder">
              <p v-if="form.sleep_time <= 6">
                {{ $t("sleep.sleep_description") }}
              </p>
              <p v-else>{{ $t("sleep.your_sleep_time") }}</p>
              <span v-if="form.sleep_time > 6" class="hours">
                {{ form.sleep_time }}
                <small>{{ $t("history.hours") }}</small>
              </span>
            </div>
          </div>
          <div class="col">
            <CircleSlider v-model="form.sleep_time" :read-only="false" />
          </div>
        </div>
      </WhiteHolder> -->

      <WhiteHolder
        class="sleep-slider-holder"
        :class="{
          'value-null': form.sleep_time === 0,
          'good-state': form.sleep_time >= 6 && form.sleep_time <= 11,
          error: v$.form.sleep_time.$error,
          'fix-btn': form.sleep_time <= 2,
        }"
      >
        <h3 class="h3">{{ $t("sleep.sleep_time") }}</h3>
        <vue-slider
          v-model="form.sleep_time"
          :height="30"
          :min="0"
          :max="20"
          tooltip="always"
          :contained="true"
        >
        </vue-slider>
      </WhiteHolder>

      <WhiteHolder
        class="sleep-slider-holder"
        :class="{
          'value-null': form.sleep_quality === 0,
          'good-state': form.sleep_quality >= 7,
          'fix-btn': form.sleep_quality <= 1,
        }"
      >
        <h3 class="h3">{{ $t("sleep.sleep_quality") }}</h3>
        <vue-slider
          v-model="form.sleep_quality"
          :height="30"
          :min="0"
          :max="10"
          tooltip="always"
          :contained="true"
        >
        </vue-slider>
      </WhiteHolder>

      <WhiteHolder v-for="(item, index) in sleepQuestions" :key="index">
        <Question
          :question="item.question"
          :answers="item.answers"
          v-model="form[item.model]"
          :error="v$.form[item.error] && v$.form[item.error].$error"
        />
      </WhiteHolder>
    </div>

    <div class="page-footer">
      <van-button
        type="primary"
        icon-position="right"
        class="button-white button-right-icon"
        @click="submitForm"
      >
        {{ $t("common.btn_sleep") }}
        <template #icon>
          <img src="@/assets/images/light-arrow-right.svg" alt="..." />
        </template>
      </van-button>
    </div>

    <van-notify v-model="showFormError" type="danger">
      <span>{{ $t("dialog.error_data_1") }}</span>
    </van-notify>
  </div>
</template>

<script>
import WhiteHolder from "@/components/WhiteHolder";
import Question from "@/components/Question";
// import CircleSlider from "@/components/CircleSlider";
import sleepQuestions from "@/mixins/sleepQuestions";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    WhiteHolder,
    Question,
    // CircleSlider,
  },
  mixins: [sleepQuestions],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {},
      showFormError: false,
    };
  },
  mounted() {
    this.form = this.data;
  },
  methods: {
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        console.log("not correct");
        this.showNotify();

        return;
      }
      console.log("correct");
      this.ifAnswerZero();

      this.$emit("onComplete", this.form);
    },
    showNotify() {
      this.showFormError = true;
      setTimeout(() => {
        this.showFormError = false;
      }, 2000);
    },
    ifAnswerZero() {
      this.sleepQuestions.forEach((el) => {
        if (!this.form[el.model] && this.form[el.model] !== 0) {
          this.form[el.model] = -1;
        }
      });
    },
  },
  validations() {
    return {
      form: {
        sleep_time: {
          required,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.sleep-radial-slider {
  .row {
    display: flex;
    justify-content: space-between;
  }

  .h3 {
    margin-bottom: 0;
  }

  .text-holder {
    max-width: 160px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #897a6c;

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .hours {
      display: flex;
      align-items: baseline;
      font-weight: 500;
      font-size: 96px;
      line-height: 1;

      small {
        font-weight: 600;
        font-size: 20px;
      }
    }
  }

  &.good-state {
    .text-holder {
      .hours {
        color: #bbd6ad;
      }
    }
  }

  &.bad-state {
    .text-holder {
      .hours {
        color: #e7dbd0;
      }
    }
  }

  &.text-bottom {
    .row {
      .col {
        &:first-child {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
}
</style>
