<template>
  <div class="mood-page">
    <div class="container">
      <h1 class="h1">{{ $t("mood.title") }}</h1>

      <WhiteHolder :class="{ error: v$.form.mood.$error }">
        <MoodPicker v-model="moodObject" ref="moodPicker" />
      </WhiteHolder>

      <WhiteHolder v-for="(item, index) in moodQuestions" :key="index">
        <Question
          :question="item.question"
          :answers="item.answers"
          v-model="form[item.model]"
        />
        <div
          v-if="item.model == 'pav' && form.pav == 1"
          class="textarea-holder"
        >
          <textarea
            v-model="form.pav_comment"
            :placeholder="$t('common.comment_placeholder')"
            class="form-textarea"
          />
        </div>
        <div
          v-if="item.model == 'meds' && form.meds == 1"
          class="textarea-holder"
        >
          <textarea
            v-model="form.meds_comment"
            :placeholder="$t('common.comment_placeholder')"
            class="form-textarea"
          />
        </div>
      </WhiteHolder>

      <WhiteHolder>
        <Question :question="$t('mood.comment')" />
        <textarea
          v-model="form.comment"
          :placeholder="$t('common.comment_placeholder')"
          class="form-textarea"
        />
      </WhiteHolder>
    </div>

    <div class="page-footer">
      <van-button
        type="primary"
        icon-position="right"
        class="button-white button-right-icon"
        @click="submitForm"
      >
        {{ $t("common.finish") }}
        <template #icon>
          <img src="@/assets/images/light-arrow-right.svg" alt="..." />
        </template>
      </van-button>
      <van-button
        type="primary"
        icon-position="left"
        class="button-white button-left-icon"
        @click="prevStep"
      >
        {{ $t("common.btn_back") }}
        <template #icon>
          <img src="@/assets/images/light-arrow-left.svg" alt="..." />
        </template>
      </van-button>
    </div>
    <van-notify v-model="showFormError" type="danger">
      <span>{{ $t("dialog.error_data_1") }}</span>
    </van-notify>
  </div>
</template>

<script>
import WhiteHolder from "@/components/WhiteHolder";
import Question from "@/components/Question";
import MoodPicker from "@/components/MoodPicker";
import moodQuestions from "@/mixins/moodQuestions.js";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  components: {
    WhiteHolder,
    Question,
    MoodPicker,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [moodQuestions],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {},
      moodObject: {},
      showFormError: false,
    };
  },
  validations() {
    return {
      form: {
        mood: {
          required,
        },
      },
    };
  },
  mounted() {
    this.form = this.data[0];
    this.moodObject = this.data[1];

    this.$refs.moodPicker.selectedMood = this.form.mood;

    const url = new URL(window.location.href);
    const t = url.searchParams.get("gender") == "female" ? true : false;

    const moodQuestions = this.moodQuestions;

    if (this.form.menstruation == undefined) {
      this.moodQuestions = moodQuestions.filter((item) => {
        return item.model !== "menstruation";
      });
    }

    if (t) {
      this.moodQuestions = moodQuestions.filter((item) => {
        return item;
      });
    }
  },
  methods: {
    async submitForm() {
      this.form.mood = this.moodObject.value;
      if (this.form.mood) {
        this.form.mood = this.moodObject.value;
      }

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        console.log("not correct");
        this.showNotify();

        return;
      }
      console.log("correct");

      this.ifAnswerZero();

      let exportData = [{ ...this.form }, { ...this.moodObject }];

      console.log(exportData);

      this.$emit("onComplete", exportData);
    },
    showNotify() {
      this.showFormError = true;
      setTimeout(() => {
        this.showFormError = false;
      }, 2000);
    },
    ifAnswerZero() {
      this.moodQuestions.forEach((el) => {
        if (!this.form[el.model] && this.form[el.model] !== 0) {
          this.form[el.model] = -1;
        }
      });
    },
    prevStep() {
      this.$emit("prevStep");
    },
  },
};
</script>

<style lang="scss" scoped>
.mood-page {
  .container {
    padding-left: 14px;
    padding-right: 16px;
  }

  .page-footer {
    padding-top: 20px;
    padding-right: 34px;
    padding-left: 46px;

    .button-white {
      &:first-child {
        margin-bottom: 14px;
      }
    }
  }

  .textarea-holder {
    padding-top: 9.95px;
  }
}
</style>
