<template>
  <div class="default-page sleep-page">
    <!-- <pre>
      {{ form }}
    </pre>
     {{ history }} -->
    <div class="page-date">{{ formatedDate }}</div>
    <Wizzard />

    <Step1
      :data="form"
      v-if="step === 1"
      @onComplete="onCompleteForm1"
      ref="form1"
    />

    <Step2
      :data="[form, moodObject]"
      v-if="step === 2"
      @prevStep="step = 1"
      @onComplete="onCompleteForm2"
      ref="form2"
    />

    <van-dialog
      v-if="!history.id"
      v-model="show"
      :title="$t('date.title')"
      :show-confirm-button="false"
      class="van-dialog-sleep"
      ref="DateModal"
    >
      <div class="modal-inner">
        <van-datetime-picker
          v-model="form.date"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          :item-height="40"
          :columns-order="['day', 'month', 'year']"
        />

        <div class="modal-btn-holder">
          <van-button
            type="primary"
            class="button-black"
            @click="show = false"
            >{{ $t("common.save") }}</van-button
          >
        </div>
      </div>
    </van-dialog>

    <NewEntryModal
      :mood="moodObject"
      :form="form"
      :date="formatedDate"
      ref="NewEntryModal"
    />
  </div>
</template>

<script>
import Step1 from "@/views/New/components/Step1";
import Step2 from "@/views/New/components/Step2";
import Wizzard from "@/components/Wizzard";
import NewEntryModal from "@/components/NewEntryModal";

import ApiCalendar from "@/api/calendar";
import { mapGetters } from "vuex";

import moment from "moment";
export default {
  components: {
    Step1,
    Step2,
    Wizzard,
    NewEntryModal,
  },

  data() {
    return {
      moodObject: {},
      minDate: new Date(1980, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      step: 1,
      show: true,
      isShowMenstrual: true,
      form: {
        date: new Date(),
        pleasure: null,
        sleep_quality: 0,
        sleep_time: 1,
        mood: {},
        formatDate: "",
      },
    };
  },
  computed: {
    formatedDate() {
      return moment(this.form.date).format("D.M.y");
    },
    ...mapGetters("History", ["history"]),
  },
  methods: {
    saveMood() {
      this.form.date = moment(this.form.date).format("D.M.y");
      if (this.form.id) {
        ApiCalendar.putRecord({
          record: this.form,
        }).then(() => {
          // let tg = window.Telegram.WebApp;
          // tg.close();

          this.$router.push(`/calendar?token=${this.$route.query.token}`);
        });
      } else {
        ApiCalendar.sendRecords({
          record: this.form,
        }).then(() => {
          // let tg = window.Telegram.WebApp;
          // tg.close();
        });
        // this.$router.push("/");
        this.$refs.NewEntryModal.handleShow();
      }
    },
    onCompleteForm1(data) {
      this.form = data;

      this.step = 2;
    },

    onCompleteForm2(data) {
      this.form = data[0];
      this.moodObject = data[1];

      this.saveMood();
    },
  },
  mounted() {
    if (this.history.id) {
      this.form = JSON.parse(JSON.stringify(this.history));
      this.moodObject.value = this.history.mood;
      this.form.date = new Date(this.history.date);
      console.log(this.history);

      this.$refs.form1.form = this.form;
    }

    if (new Date().getHours() < 5) {
      this.maxDate[2] = new Date().getDate() - 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.sleep-page {
  .sleep-sleeder-holder {
    padding: 15px 44px 31px 19px;
  }

  .sleep-assessment {
    .h3 {
      margin-bottom: -7px;
    }
  }

  .container {
    padding-left: 14px;
    padding-right: 16px;
  }

  .modal-inner {
    .modal-btn-holder {
      padding-top: 14px;
      padding-left: 47px;
      padding-right: 47px;
      padding-bottom: 26px;
    }
  }
}

.form__sleep_time {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
</style>
